import React, { useState, useEffect } from 'react';
import Data from './data.json';

export default function App() {
    const [currentData, setCurrentData] = useState(null);
    const [userAnswers, setUserAnswers] = useState({});
    const [score, setScore] = useState(null);
    const [shuffledData, setShuffledData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [totalCorrect, setTotalCorrect] = useState(0);

    const shuffle = (array) => {
        let shuffled = array.slice();
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const loadNewQuestion = () => {
        if (currentIndex < shuffledData.length) {
            setUserAnswers({});
            setScore(null);
            setCurrentData(shuffledData[currentIndex]);
            setCurrentIndex(prev => prev + 1);
        } else if (currentIndex !== 0) {
            alert(`You've answered all the questions! You got ${totalCorrect} out of ${shuffledData.length * 4} correct!`);

        }
    };

    useEffect(() => {
        setShuffledData(shuffle(Data));
    }, []);

    useEffect(() => {
        if (shuffledData.length > 0) {
            loadNewQuestion();
        }
    }, [shuffledData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const keysToCheck = ['botanicalName', 'cultivar', 'commonName', 'familyName'];
        let correctAnswers = 0;

        keysToCheck.forEach(key => {
            if (StringComparison(currentData[key], userAnswers[key])) {
                correctAnswers += 1;
            }
        });

        setScore(correctAnswers);
        setTotalCorrect(prev => prev + correctAnswers);
    };

    const determineColor = (key) => {
        if (StringComparison(currentData[key],userAnswers[key])) {
            return "green";
        } else {
            return "red";
        }
    };

    function StringComparison(a,b) {
        // Check if string 'a' is null or empty
        if (!a || a.trim() === '') {
            return !b || b.trim() === '';
        }

        // Check if 'a' is equal to 'b'
        return a === b;
    }

    return (
        <main className="app-container">
            {currentData && (
                <>
                    <div className="image-container">
                        <img src={"/images/" + currentData.image} alt="Leaf"/>
                    </div>
                    <div className="answers-container"
                         style={{opacity: score !== null ? 1 : 0, visibility: score !== null ? 'visible' : 'hidden'}}>
                        <h1 style={{color: determineColor('botanicalName')}}><b>Botanical
                            Name:</b> {currentData.botanicalName}</h1>
                        <h2 style={{color: determineColor('cultivar')}}>
                            <b>Cultivar/Subspecies:</b> {currentData.cultivar}</h2>
                        <h2 style={{color: determineColor('commonName')}}><b>Common Name:</b> {currentData.commonName}
                        </h2>
                        <h2 style={{color: determineColor('familyName')}}><b>Family Name:</b> {currentData.familyName}
                        </h2>
                    </div>
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label>Botanical Name:</label>
                                <input
                                    value={userAnswers.botanicalName || ""}
                                    onChange={(e) => setUserAnswers({...userAnswers, botanicalName: e.target.value})}
                                />
                            </div>
                            <div>
                                <label>Cultivar/Subspecies:</label>
                                <input
                                    value={userAnswers.cultivar || ""}
                                    onChange={(e) => setUserAnswers({...userAnswers, cultivar: e.target.value})}
                                />
                            </div>
                            <div>
                                <label>Common Name:</label>
                                <input
                                    value={userAnswers.commonName || ""}
                                    onChange={(e) => setUserAnswers({...userAnswers, commonName: e.target.value})}
                                />
                            </div>
                            <div>
                                <label>Family Name:</label>
                                <input
                                    value={userAnswers.familyName || ""}
                                    onChange={(e) => setUserAnswers({...userAnswers, familyName: e.target.value})}
                                />
                            </div>
                            <button onClick={handleSubmit}>Submit</button>
                            <button onClick={loadNewQuestion} disabled={score === null}>Next Question</button>
                        </form>
                        <p>&#8203;{score !== null && "You got " + score + " out of 4 correct!"}</p>
                    </div>
                </>
            )}
        </main>
    );
}
